<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" >
      <el-col :lg="12" :md="12" :sm="16" :xs="16" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Buscar" v-model="searchForm.query" v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
        <el-button
          class="margin-left-20"
          @click.stop.prevent="handleSearchFilter"
          type="primary"
          icon="el-icon-search"
          size="mini"
        >Buscar</el-button>
        <el-button
          class="margin-left-20"
          @click.stop.prevent="handleClean"
          type="warning"
          icon="el-icon-delete"
          size="mini"
        >Limpiar Filtros</el-button>
        <el-button
        class="margin-left-20"
          @click.stop.prevent="exportExcelFile"
          type="success"
          icon="el-icon-bottom"
          size="mini"
        >Exportar Excel</el-button>
        <el-button
        class="margin-left-20"
          @click.stop.prevent="handleCreated"
          type="success"
          icon="el-icon-circle-plus-outline"
          size="mini"
        >Nuevo</el-button>
    </el-row>
     <el-row>
      <el-col :span="5" class=" margin-left-10">
        <div class=" margin-left-10">
          <span class=" margin-filter">Fecha inicial</span> <br>
          <el-date-picker
          class=" margin-left-10"
            @change="handleSearchFilter()"
            size="mini"
            v-model="searchForm.initDate"
            type="date"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Mayor a ">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="5" class=" margin-left-10">
        <div class=" margin-left-10">
          <span class=" margin-filter">Fecha final</span> <br>
          <el-date-picker
          class=" margin-left-10"
            @change="handleSearchFilter()"
            size="mini"
            v-model="searchForm.endDate"
            type="date"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Menor a">
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
        <el-pagination small @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
    <el-table
      v-loading="loading"
      :data="list"
      element-loading-text="Cargando"
      fit
      highlight-current-row
      style="width: 100%"
      size="mini"
    >
       <el-table-column type="expand">
        <template slot-scope="scope">
          <span>ID:  {{ scope.row._id }}</span><br>
          <span>ID Usuario:  {{ scope.row.idEmployed }}</span><br>
          <span>Nombre:  {{ scope.row.name }}</span><br>
          <span>Puesto:  {{ scope.row.job }}</span><br>
          <span>Correo:   {{ scope.row.email }}</span><br>
          <span>Correo verificado:   {{ scope.row.isEmailVerified?'Verificado':'No verificado', }}</span><br>
          <span>Telefono:   {{ scope.row.phoneCelular }}</span><br>
          <span>Telefono verificado:   {{ scope.row.isPhoneVerified?'Verificado':'No verificado', }}</span><br>
          <span>RFC:   {{ scope.row.RFC }}</span><br>
          <span>CURP:  {{ scope.row.CURP }}</span><br>
          <span>NSS:   {{ scope.row.NSS }}</span><br>
          <span>Días de Vacaciones:   {{ scope.row.vacationDays }}</span><br>
          <span>Periodo de Vacaciones:   {{ scope.row.vacationPeriod }}</span><br>
          <span>Fecha de Nacimiento:   {{ scope.row.birthDate }}</span><br>
          <span>Fecha Ingreso:  {{ scope.row.dateAntiquity }}</span><br>
          <span>ID Puesto:   {{ scope.row.idJob }}</span><br>
          <span>ID Unidad:   {{ scope.row.idUnit }}</span><br>
          <span>Unidad:   {{ scope.row.unit }}</span><br>
          <span>ID Equipo:   {{ scope.row.idTeam }}</span><br>
          <span>ID Lugar de trabajo:   {{ scope.row.idWorkPlace }}</span><br>
          <span>Lugar de Trabajo:   {{ scope.row.workPlace }}</span><br>
          <span>ID región:   {{ scope.row.idRegion }}</span><br>
          <span>Región:  {{ scope.row.region }}</span><br>
          <span>Estatus:  {{ scope.row.status?'Activo':'Inactivo' }}</span><br>
          <span>Tipo de Registro:  {{ scope.row.isManual?'Manual':'Meta4' }}</span><br>
          <span>Actualizado:  {{ scope.row.updated_at | formatDateTime }}</span><br>
          <span>Registro:  {{ scope.row.created_at | formatDateTime }}</span><br>
          <span>Contactos de emergencia:</span><br>
          <span>Nombre:  {{ scope.row.emergencyContactName }}</span><br>
          <span>Teléfono:  {{ scope.row.emergencyContactPhone }}</span><br>
          <span>Audio:  {{ scope.row.nameAudio }}</span><br>
          <!-- <audio :src="scope.row.nameAudio" controls="controls">
            Tu navegador no soporta el elemento <code>audio</code>.
          </audio> -->

        </template>
      </el-table-column>
      <el-table-column label="ID Usuario" align="center" width="80" >
        <template slot-scope="scope">
          <span>{{ scope.row.idEmployed }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nombre" align="center" width="180%" >
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>

      </el-table-column>
       <el-table-column label="RFC" align="center"  width="140">
        <template slot-scope="scope">
          <span>{{ scope.row.RFC }}</span>
        </template>

      </el-table-column>
      <el-table-column label="Teléfono" align="center" width="140">
         <template slot-scope="scope" >
          <span>{{ scope.row.phoneCelular }}</span>
        </template>

      </el-table-column>
      <el-table-column label="Fecha Actualizado" align="center" width="150">
        <template slot-scope="scope" >
          <span>{{ scope.row.updated_at | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Fecha finalización" align="center" width="150">
        <template slot-scope="scope" >
          <span>{{ scope.row.endDate | formatDate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Reproducir" align="center" width="90">
          <template slot-scope="scope" >
             <el-button
              @click.stop.prevent="playAudioFile(scope.$index, scope.row)"
              size="small"
              type="primary"
              circle
              icon="el-icon-video-play"
              :disabled="scope.row.nameAudio== null || scope.row.nameAudio == ''? true : false"
            ></el-button>
          </template>
      </el-table-column>
      <el-table-column label="Audio" align="center" width="120">
        <template slot-scope="prop" >
          <!-- <div v-if="scope.row."> -->
            <el-upload
              name="file"
              action="#"
              :data="prop.row"
              :show-file-list="false"
              :http-request="postAudio"
              :before-upload="beforeAvatarUpload"
              :on-success="handleAvatarSuccessAudio"
              >
              <el-button slot="trigger" v-if="prop.row.nameAudio == null || prop.row.nameAudio == ''" size="mini" round type="warning">
                Cargar Audio
              </el-button>
              <el-button v-else-if="prop.row.nameAudio" slot="trigger" size="mini" round type="success">Editar Audio</el-button>
            </el-upload>
        </template>
      </el-table-column>

      <el-table-column label="Operación" align="center" width="220">
          <template slot-scope="scope" >
             <!-- <el-button
              @click.stop.prevent="playAudioFile(scope.$index, scope.row)"
              size="mini"
              type="primary"
              round
            >Reproducir</el-button> -->
            <el-button
              @click.stop.prevent="handleEdit(scope.$index, scope.row)"
              size="mini"
              type="primary"
              round
            >Editar</el-button>
            <el-button
              @click.stop.prevent="handleDisable(scope.$index, scope.row)"
              size="mini"
              type="danger"
              round
              :disabled="scope.row.status? false : true"
            >Desactivar</el-button>
          </template>
        </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
  </div>
</template>
<script>
import { search, getPage, destroyUser, updateUserAudio, exportExcel, playAudio } from '@/api/user'
import { uploadAudio } from '@/api/fileUpload'
// import Vue from 'vue'
// Vue.prototype.$idUser = ''
export default {
  data () {
    return {
      searchForm: {
        query: '',
        initDate: '',
        endDate: ''
      },
      form: {
        nameAudio: ''
      },
      idUser: '',
      totalPages: '',
      totalItemPage: '',
      currentPage: '',
      list: null,
      loading: false,
      // loadingAudio: false
      fuenteDeReproduccion: '',
      audioCtx: ''
    }
  },
  mounted () {
    this.handleSearchFilter()
  },
  methods: {
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.showItempageValue)
        .then(response => {
          this.list = response.data.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      await getPage(this.currentPage, val)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearchFilter () {
      this.loading = true
      await search(this.searchForm)
        .then(response => {
          console.log(response.data)
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCreated () {
      this.$router.push({ name: 'users.create' })
    },
    handleEdit (index, row) {
      console.log(row)
      this.$router.push({ name: 'users.edit', params: { _id: row._id } })
    },
    handleAudio (index, row) {
      console.log(row)
      this.$router.push({ name: 'users.edit', params: { _id: row._id } })
    },
    handleClean () {
      this.searchForm.query = ''
      this.searchForm.initDate = ''
      this.searchForm.endDate = ''
      this.handleSearchFilter()
    },
    handleDisable (index, row) {
      this.loadingDelete = true
      this.$confirm('Se desactivará permanentemente. ¿Continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await destroyUser(row._id)
            .then(response => {
              this.list = response.data.data
              this.totalPages = response.data.total
              this.$message({
                showClose: true,
                message: 'Se desactivo correctamente!',
                type: 'success'
              })
              // this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          })
        })
        .finally(() => (this.loadingDelete = false))
    },
    async postAudio (param) {
      // console.log(param)
      this.loading = true
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('folder', 'audios/users')
      // formData.append('prefix', '/')
      uploadAudio(formData).then((response) => {
        console.log(response)
        var ress = []
        ress.push({
          id: param.data._id,
          response: response
        })
        // console.log(ress)
        param.onSuccess(ress)
      })
        .catch(response => {
          console.log(response)
          param.onError()
        })
        .finally(() => (this.loading = false))
    },
    handleAvatarSuccessAudio (res, file) {
      console.log(res[0].response.path)
      this.form.nameAudio = res[0].response.path
      this.idUser = res[0].id
      this.onUpdate()
    },
    async onUpdate () {
      console.log(this.idUser)
      await updateUserAudio(this.idUser, this.form)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
          this.$message({
            showClose: true,
            message: 'Se ha cargado el audio correctamente!',
            type: 'success'
          })
          this.idUser = ''
          // this.$router.push({ name: 'users' })
        })
        .catch(this.responseCatch)
    },
    beforeAvatarUpload (file) {
      // console.log(file)
      const isMP3 = file.type === 'audio/mpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isMP3) {
        this.$message.error('¡El audio debe estar en formato mp3!')
      }
      if (!isLt2M) {
        this.$message.error('¡El audio excede los 2MB!')
      }
      return isMP3 && isLt2M
    },
    async exportExcelFile () {
      this.loading = true
      await exportExcel(this.searchForm)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          const name = 'Reporte_de_usuarios_' + new Date() + '.xlsx'
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    // TODO: Descargar Archivo de audio
    // async downloadFileAudio (index, row) {
    //   console.log(row.nameAudio)
    //   this.loading = true
    //   await downloadAudio({ file: row.nameAudio })
    //     .then((response) => {
    //       const url = window.URL.createObjectURL(new Blob([response]))
    //       const link = document.createElement('a')
    //       link.href = url
    //       const name = 'audio.mp3'
    //       link.setAttribute('download', name)
    //       document.body.appendChild(link)
    //       link.click()
    //     })
    //     .catch(this.responseCatch)
    //     .finally(() => (this.loading = false))
    // }
    async playAudioFile (index, row) {
      // this.loading = true
      this.audioCtx = new (window.AudioContext || window.webkitAudioContext)()
      await playAudio({ file: row.nameAudio })
        .then((response) => {
          var audio = new Audio()
          audio.src = window.URL.createObjectURL(new Blob([response]))
          this.fuenteDeReproduccion = this.audioCtx.createMediaElementSource(audio)
          this.fuenteDeReproduccion.connect(this.audioCtx.destination)
          audio.play()
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    }
  }
}
</script>
